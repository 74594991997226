var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","hide-default-footer":"","loading-text":"Đang tải dữ liệu","item-key":"id","show-select":"","headers":_vm.headers,"items":_vm.promotions,"loading":_vm.isLoading},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"110px"}},[_vm._v(" "+_vm._s(item.type === 1 ? "Theo sản phẩm" : "Theo hóa đơn")+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"250px"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.promotion_type",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.PROMOTION_TYPE[item.promotion_type].label))])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"200px"},domProps:{"innerHTML":_vm._s(_vm.removeHTMLTags(item.note))}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.PROMOTION_STATUS[item.status].color,"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.getLabelOfStatus(item))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('button-action-url',{attrs:{"slug":item.slug}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('modal-pre-order',{attrs:{"product-option-id":_vm.selectedProductOptionId},on:{"updatedPreOrderInfo":function($event){return _vm.$emit('updatedPreOrderInfo')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }