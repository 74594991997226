<template>
  <div>
    <div class="d-flex">
      <v-radio-group v-model="preOrderInfo.discountType" class="mt-0" row>
        <v-radio label="Giảm giá theo %" value="PERCENT"></v-radio>
        <v-radio label="Giảm tiền cố định" value="DONG"></v-radio>
      </v-radio-group>

      <div style="width:200px">
        <v-text-field
          v-if="preOrderInfo.discountType === 'PERCENT'"
          v-model="preOrderInfo.decreasedPercents"
          class="text-body-1 mt-0 pt-0"
          dense
          :disabled="!isOpenPreOrder"
          outlined
          required
          :min="0"
          :rules="
            isOpenPreOrder
              ? [
                  v => !!v || 'Không được bỏ trống',
                  v => (v && v >= 0) || 'Không được bé hơn 0',
                  v => (v && v <= 99) || 'Giá trị tối đa là 99'
                ]
              : []
          "
          type="number"
        >
          <template v-slot:append>
            <div class="mt-1">%</div>
          </template>
        </v-text-field>

        <tp-input-price
          v-if="preOrderInfo.discountType === 'DONG'"
          v-model="preOrderInfo.decreasedMoney"
          custom-class="text-body-1 mt-0 pt-0"
          dense
          outlined
          :validate="isOpenPreOrder ? 'required' : ''"
        ></tp-input-price>
      </div>
    </div>

    <div class="mb-1 font-weight-bold text-subtitle-1">Quà tặng kèm theo</div>

    <vue-editor
      v-model="preOrderInfo.gifts"
      :editor-options="editorSettings"
      :editor-toolbar="customToolbar"
    ></vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },

  props: {
    isOpenPreOrder: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      customToolbar: [[{ list: "bullet" }], ["link"]],
      editorSettings: {
        modules: {
          clipboard: {
            matchers: [[Node.ELEMENT_NODE, this.customQuillClipboardMatcher]]
          }
        }
      }
    };
  },

  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    preOrderInfo() {
      return this.productOption.pre_order_info;
    }
  },

  methods: {
    customQuillClipboardMatcher(node, delta) {
      delta.ops = delta.ops.map(op => {
        return {
          insert: op.insert
        };
      });
      return delta;
    }
  }
};
</script>

<style lang="scss">
.prewrap {
  white-space: pre-wrap;
}

.ql-snow a {
  color: $color-primary !important;
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
  color: $color-primary !important;
}

.ql-snow .ql-tooltip {
  left: 0px !important;
}
</style>
