<template>
  <div>
    <date-picker
      v-model="dateTimeRange"
      :disabled-date="disabledPast"
      format="DD/MM/YYYY HH:mm"
      type="datetime"
      range
      placeholder="Chọn khoảng thời gian"
    ></date-picker>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "@/core/scss/datepicker.scss";

import "vue2-datepicker/locale/vi";

export default {
  components: { DatePicker },

  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    dateTimeRange: {
      get: function() {
        return this.productOption.pre_order_info.startAt
          ? [
              new Date(this.productOption.pre_order_info.startAt),
              new Date(this.productOption.pre_order_info.endAt)
            ]
          : [new Date(), new Date()];
      },
      set: function(newValue) {
        this.productOption.pre_order_info.startAt = newValue[0];
        this.productOption.pre_order_info.endAt = newValue[1];
      }
    }
  },

  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null
      },
      value2: [new Date(2019, 9, 8), new Date(2019, 9, 19)]
    };
  },

  methods: {
    disabledPast(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    }
  }
};
</script>
