<template>
  <div>
    <v-menu close-on-content-click :nudge-width="200" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="indigo" dark v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <card-copy-slug
          class="pa-4"
          :slug="`/khuyen-mai/${slug}`"
        ></card-copy-slug>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import CardCopySlug from "@/modules/Promotion/pages/Detail/components/CardCopySlug.vue";

export default {
  components: {
    CardCopySlug
  },
  props: {
    slug: {
      type: String,
      default: "",
      require: true
    }
  }
};
</script>

<style></style>
