<template>
  <div class="quantity-wrapper">
    <v-checkbox
      v-model="preOrderInfo.isLimited"
      class="mt-0"
      dense
      hide-details
      label="Không giới hạn"
    ></v-checkbox>

    <div>Đã đặt: {{ preOrderInfo.totalOrdered }}</div>

    <div v-if="!preOrderInfo.isLimited" class="d-flex align-item-center">
      <div class="mr-2">Còn lại:</div>

      <tp-text-field
        v-model="preOrderInfo.limit"
        class="text-body-1 -mt-2"
        dense
        outlined
        type="number"
        :validate="isOpenPreOrder ? 'required' : ''"
      ></tp-text-field>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpenPreOrder: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    preOrderInfo() {
      return this.productOption.pre_order_info;
    }
  },

  data() {
    return {
      isUnlimited: true
    };
  }
};
</script>

<style lang="scss" scoped>
.number-input {
  width: 150px;
}

.quantity-wrapper {
  display: flex;
  align-items: baseline;
  gap: 80px;
}

.-mt-2 {
  margin-top: -8px;
}
</style>
