<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :options="searchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
      <div class="action-row--col">
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          @click="gotoDetailPage()"
        >
          <v-icon left>mdi-plus</v-icon> Thêm khuyến mại
        </v-btn>
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
        <!-- Start: Action dropdown -->
        <tp-btn-overflow
          v-if="selectedPromotions.length > 0"
          btn-class="white rounded-lg ml-3"
          :active-item="false"
          :items="actionList"
          placeholder="Thao tác"
          @select="selectAction($event)"
        ></tp-btn-overflow>
        <!-- End: Action dropdown -->
        <div class="ml-3" v-if="selectedPromotions.length > 0">
          Đã chọn <strong>{{ this.selectedPromotions.length }}</strong> khuyến
          mãi
        </div>
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedPromotions: {
      type: Array,
      required: true
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    }
  },
  data() {
    return {
      actionList: [
        { id: "stop", text: "Dừng" },
        { id: "start", text: "Khởi động lại" }
      ],
      searchOptions: [{ type: "search", label: "Tìm kiếm" }],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["PROMOTION/paginationInfo"];
    },
    promotionStatusRequest() {
      return this.$store.getters["PROMOTION/statusRequest"];
    }
  },
  methods: {
    async gotoDetailPage() {
      await this.$store.dispatch("PROMOTION/resetPromotion");
      await this.$router.push({ name: "promotion_create" });
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    },
    selectAction(val) {
      if (val.id === "stop") {
        this.stopPromotions();
      } else if (val.id === "start") {
        this.startPromotions();
      }
    },
    async stopPromotions() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: "<span>Dừng những khuyến mại này</span>",
          message:
            "Khuyến mại đang được chọn sẽ bị dừng ngay bây giờ. Mọi thay đổi chưa được lưu sẽ bị mất. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Dừng",
            onClickHandler: async () => {
              await Promise.all(
                this.selectedPromotions.map(promotion => {
                  return this.$store.dispatch(
                    "PROMOTION/disablePromotion",
                    promotion.id
                  );
                })
              );
              // Alert
              if (
                this.promotionStatusRequest.value === "success-disablePromotion"
              ) {
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã dừng khuyến mại"
                  }
                });
              }
              const { query } = this.$route;
              this.$emit("getPromotions", query);
              this.$emit("updateSelectedPromotions", []);
            }
          }
        }
      });
    },
    async startPromotions() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: "<span>Khởi động những khuyến mại</span>",
          message:
            "Khuyến mại đang được chọn sẽ được khởi động lại ngay bây giờ và không có thời gian kết thúc. Mọi thay đổi chưa được lưu sẽ bị mất. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "primary",
            text: "Khởi động",
            onClickHandler: async () => {
              await Promise.all(
                this.selectedPromotions.map(promotion => {
                  return this.$store.dispatch(
                    "PROMOTION/enablePromotion",
                    promotion.id
                  );
                })
              );
              // Alert
              if (
                this.promotionStatusRequest.value === "success-enablePromotion"
              ) {
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã khởi động lại khuyến mại"
                  }
                });
              }
              const { query } = this.$route;
              this.$emit("getPromotions", query);
              this.$emit("updateSelectedPromotions", []);
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
