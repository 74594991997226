<template>
  <div>
    <v-data-table
      v-model="selected"
      class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      hide-default-footer
      loading-text="Đang tải dữ liệu"
      item-key="id"
      show-select
      :headers="headers"
      :items="promotions"
      :loading="isLoading"
      @click:row="viewDetail($event)"
    >
      <template v-slot:[`item.type`]="{ item }">
        <div style="width: 110px">
          {{ item.type === 1 ? "Theo sản phẩm" : "Theo hóa đơn" }}
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div style="width: 250px">
          {{ item.name }}
        </div>
      </template>

      <template v-slot:[`item.promotion_type`]="{ item }">
        <div>{{ PROMOTION_TYPE[item.promotion_type].label }}</div>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        <div v-html="removeHTMLTags(item.note)" style="width: 200px"></div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          class="font-weight-bold"
          :color="PROMOTION_STATUS[item.status].color"
          small
          outlined
        >
          {{ getLabelOfStatus(item) }}
        </v-chip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <button-action-url :slug="item.slug"></button-action-url>
      </template>
    </v-data-table>

    <modal-pre-order
      :product-option-id="selectedProductOptionId"
      @updatedPreOrderInfo="$emit('updatedPreOrderInfo')"
    ></modal-pre-order>
  </div>
</template>

<script>
import {
  HEADERS_PROMOTION_TABLE,
  PROMOTION_STATUS,
  PROMOTION_TYPE
} from "@/modules/Promotion/constants";
import ButtonActionUrl from "./ButtonActionUrl.vue";
import ModalPreOrder from "./modal/ModalPreOrder.vue";

export default {
  components: {
    ButtonActionUrl,
    ModalPreOrder
  },
  props: {
    selectedPromotions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: HEADERS_PROMOTION_TABLE,
      PROMOTION_STATUS: PROMOTION_STATUS,
      PROMOTION_TYPE: PROMOTION_TYPE,
      selectedProductOptionId: null
    };
  },
  computed: {
    promotions() {
      return this.$store.getters["PROMOTION/promotions"];
    },

    promotionStatusRequest() {
      return this.$store.getters["PROMOTION/statusRequest"];
    },

    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    },

    selected: {
      get() {
        return this.selectedPromotions;
      },
      set(val) {
        this.$emit("updateSelectedPromotions", val);
      }
    },

    isLoading() {
      return (
        this.promotionStatusRequest.value === "loading-getPromotions" ||
        this.productOptionStatusRequest.value === "loading-getProductOptionById"
      );
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    getIndexOfPromotion(val) {
      const arr = this.promotions.map(item => item.id);

      return arr.indexOf(val);
    },

    getLabelOfStatus(item) {
      return `${
        this.PROMOTION_STATUS[item.status].text
      } ${this.PROMOTION_STATUS[item.status].relativeDate(
        item.time_from,
        item.time_to
      ) || ""}`;
    },

    async handleOpenModal(productOptionId) {
      await this.$store.dispatch("PRODUCT_OPTION/getProductOptionById", {
        productOptionId: productOptionId
      });

      this.$modal.show({ name: "pre-order-modal" });
    },

    removeHTMLTags(str) {
      if (!str) return "";
      return str.replace(/<[^>]*>/g, "");
    },

    async viewDetail(item) {
      if (item.promotion_type === 6) {
        this.handleOpenModal(item.product_option_id);
        this.selectedProductOptionId = item.product_option_id;
        return;
      }

      await this.$router.push({
        name: "promotion_detail",
        params: {
          promotionId: item.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
