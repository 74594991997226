<template>
  <tp-modal name="pre-order-modal" width="805px">
    <v-card class="text-body-1" flat>
      <v-toolbar
        class="px-2 pb-0 align-start"
        elevation="0"
        extension-height="40px"
        height="56x"
      >
        <v-toolbar-title class="font-weight-bold">
          Pre Order
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          :loading="preOrderStatusRequest.value === 'loading-updatePreOrder'"
          text
          @click="handleSavePreOrder"
        >
          Lưu
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-form ref="form">
          <v-card class="px-3 py-4" flat>
            <div v-if="preOrderInfo">
              <v-switch
                v-model="preOrderInfo.isEnabled"
                class="mt-0"
                :label="
                  preOrderInfo.isEnabled
                    ? 'Pre-order đang mở'
                    : 'Pre-order đã kết thúc'
                "
              ></v-switch>

              <v-card class="px-5" flat>
                <v-overlay
                  :absolute="true"
                  :opacity="0.6"
                  :value="!preOrderInfo.isEnabled"
                  color="#f7f7f7"
                ></v-overlay>
                <div class="pa-2 yellow lighten-5">
                  <span
                    class="font-weight-bold
        "
                    >Lưu ý:</span
                  >
                  Cài đặt Pre-order sẽ được ưu tiên, các chính sách khuyến mãi
                  khác sẽ bị ẩn đi trên giao diện khách hàng. Nhưng SKU vẫn nằm
                  trong danh sách các sản phẩm khuyến mãi của campaign liên
                  quan.
                </div>

                <div class="my-5">
                  <div class="font-weight-bold text-subtitle-1">Số lượng</div>

                  <pre-order-quantity
                    :is-open-pre-order="preOrderInfo.isEnabled"
                  ></pre-order-quantity>
                </div>

                <v-divider></v-divider>

                <div class="my-5">
                  <div class="font-weight-bold text-subtitle-1">
                    Ưu đãi đặt trước
                  </div>

                  <pre-order-offer
                    :is-open-pre-order="preOrderInfo.isEnabled"
                  ></pre-order-offer>
                </div>

                <v-divider></v-divider>

                <div class="my-5">
                  <div class="font-weight-bold text-subtitle-1">Thời gian</div>

                  <pre-order-time></pre-order-time>
                </div>
              </v-card>
            </div>
          </v-card>
        </v-form>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import PreOrderOffer from "@/core/components/modals/ModalProductOption/components/TabPreOrder/components/PreOrderOffer.vue";
import PreOrderQuantity from "@/core/components/modals/ModalProductOption/components/TabPreOrder/components/PreOrderQuantity.vue";
import PreOrderTime from "@/core/components/modals/ModalProductOption/components/TabPreOrder/components/PreOrderTime.vue";

export default {
  components: {
    PreOrderOffer,
    PreOrderQuantity,
    PreOrderTime
  },
  props: {
    productOptionId: {
      type: Number,
      default: null
    }
  },

  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    preOrderInfo() {
      return this.productOption.pre_order_info;
    },

    preOrderStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "pre-order-modal" });
    },

    async handleSavePreOrder() {
      if (!this.$refs.form.validate()) return;

      await this.$store.dispatch("PRODUCT_OPTION/updatePreOrder", {
        pre_order_info: this.preOrderInfo,
        id: this.productOptionId
      });

      if (this.preOrderStatusRequest.value === "success-updatePreOrder") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật thành công"
          }
        });

        this.$emit("updatedPreOrderInfo");
      }
    }
  }
};
</script>
